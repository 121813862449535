// Main colors
$helipagos-primary: #53baab;
$helipagos-primary-alt: #6abea7;
$helipagos-secondary: #1a3b36;
$helipagos-400: #53baaa2a;
$helipagos-600: #5eada22a;
$helipagos-650: #135e525d;
$helipagos-700: #3e6146;
// Seconadry colors - helpers
$grey-md: #717171;
$grey-sm: #dbdbdb;
$grey-200: #a0aab2;
$grey-400: #7e9181;
$white: #fff;

$orange: #ffba6a;

$red-500: #f74d40;
$red-700: #eb1000;

// Material text
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

// Material Palette

$helipagos-palette: (
  50: #f0f1ff,
  100: #e9f5ef,
  200: #69d499b6,
  300: #9bdaba,
  400: #7dd8b2,
  500: #53baab,
  600: #4faf5c,
  700: #3e6146,
  800: #3c8655,
  900: #255340,
  A100: #71ff7db6,
  A200: #53baab,
  A400: #3c864e,
  A700: #25533c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Fonts
$Roboto-Regular: url(./assets/fonts/Roboto-Regular.ttf);
$Roboto-Black: url(./assets/fonts/Roboto-Black.ttf);
$Roboto-BlackItalic: url(./assets/fonts/Roboto-BlackItalic.ttf);
$Roboto-Bold: url(./assets/fonts/Roboto-Bold.ttf);
$Roboto-BoldItalic: url(./assets/fonts/Roboto-BoldItalic.ttf);
$Roboto-Italic: url(./assets/fonts/Roboto-Italic.ttf);
$Roboto-BoldItalic: url(./assets/fonts/Roboto-BoldItalic.ttf);
$Roboto-Light: url(./assets/fonts/Roboto-Light.ttf);
$Roboto-LightItalic: url(./assets/fonts/Roboto-LightItalic.ttf);
$Roboto-Medium: url(./assets/fonts/Roboto-Medium.ttf);
$Roboto-MediumItalic: url(./assets/fonts/Roboto-MediumItalic.ttf);
$Roboto-Regular: url(./assets/fonts/Roboto-Regular.ttf);
$Roboto-Thin: url(./assets/fonts/Roboto-Thin.ttf);
$Roboto-ThinItalic: url(./assets/fonts/Roboto-ThinItalic.ttf);
