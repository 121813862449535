/* You can add global styles to this file, and also import other style files */

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap'); */
@import "theme/variables";
@import url("./assets/css/loading.min.css");
@font-face {
  font-family: Roboto;
  src: $Roboto-Regular, $Roboto-Black, $Roboto-BlackItalic, $Roboto-Bold,
    $Roboto-BoldItalic, $Roboto-Italic, $Roboto-BoldItalic, $Roboto-Light,
    $Roboto-LightItalic, $Roboto-Medium, $Roboto-MediumItalic, $Roboto-Regular,
    $Roboto-Thin, $Roboto-ThinItalic;
}

* {
  // TODO: Eliminacion gradual de dichas variables provisorias

  --grey-md: #717171;
  --grey-sm: #dbdbdb;

  font-family: "Roboto", sans-serif;

  /* Scrolling styles */
  scrollbar-width: 5px;
  scrollbar-color: $helipagos-primary;
}

body {
  background-color: #eee !important;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

/*Sidebar*/

.open-sidebar {
  /* transition: all 0.5s ease 0s; */
  /* -webkit-transition: all 0.5s ease 0s; */
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -ms-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  transition: all 0.5s ease 0s;
  left: 0px !important;
}

.opacity {
  /* transition: all 0.5s ease 0s; */
  /* -webkit-transition: all 0.5s ease 0s; */
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -ms-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  transition: all 0.5s ease 0s;
  display: block !important;
}

/** Table **/

.target {
  color: white;
  padding: 2px 4px;
  width: 100px;
  text-align: center;
}

.success {
  background-color: #53baab;
}

.conciliada {
  background-color: #53baab;
}

.procesada {
  background-color: #009fe3;
}

.generada {
  background-color: #cecece;
}

.cancel {
  background-color: #595959;
}

.info {
  background-color: #f9b233;
}

.rechazada {
  background-color: #ff6e6e;
}

.contracargo {
  background-color: #934bdb;
}

.contracargo_liquidado{
  background-color: #934bdb;
  white-space: pre-line;
}

.warning {
  background-color: #fcdf03;
}

.transferencia {
  background-color: #77dd77;
}
.transferencia_generada {
  background-color: #c7f7f7;
}
.conciliado {
  background-color: #008772;
}

.devolucion_proceso{
  background-color: #ffcc50;
  white-space: pre-line;
}

.devolucion_conciliado{
  background-color: #ff9800;
  white-space: pre-line;
}

.duplicada_proceso{
  background-color: #dbeddc;
  white-space: pre-line;
}

.duplicada_conciliado{
  background-color:#3c6c42;
  white-space: pre-line;
}

.duplicada{
  background-color:#008080;
}

.bloqueada{
  background-color:#000000;
}

.chargeback {
  background-color: #ffa07a;
}
.ceroConciliacion {
  color: #dc3545;
}
.cienConciliacion {
  color: #77dd77;
}

.porcentaje {
  color: #934bdb;
}
.valorNegativo {
  color: #dc3545;
}
.estado_en_proceso{
  background-color: #CECECE;
  color: white;
  white-space: pre-line;
}

.estado_valido{
  color: white;
  background-color:#008080;
}

.estado_error{
  color: white;
  background-color:#CC0000;
  padding: 5px;
  
}


.estado_en_proceso{
  background-color: #CECECE;
  color: white;
  white-space: pre-line;
}

.estado_valido{
  color: white;
  background-color:#008080;
}

.estado_error{
  color: white;
  background-color:#CC0000;
  padding: 5px;
  
}

.generador{
  background-color: #53baab;
}

.notificacion{
  background-color: #f9b233;
  white-space: pre-line;
}

/* Scroll */

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-moz-scrollbar {
  width: 2px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #53baab;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4eb5a6;
}
::-moz-scrollbar-thumb:hover {
  background: #4eb5a6;
}

a {
  text-decoration: none;
}

/* Cards */

.card {
  border: 0px;
  border-radius: 10px;
}

.card a {
  color: #53baab;
  text-decoration: none;
  /* font-weight: 600; */
}

.card-header {
  border-radius: 10px 10px 0px 0px !important;
  background-color: white;
  border-bottom: 2px solid #eee;
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  color: #717171;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

.card-body {
  padding: 30px;
}

.card-body p {
  font-size: 12px;
  color: #717171;
  margin-bottom: 0px;
}

.card-footer {
  background-color: white;
}

.card-footer button {
  float: right;
  margin: 0 0.5rem;
}

@media (max-width: 767px) {
  .card {
    margin-bottom: 1rem;
  }
}

/** CARD not-found **/

.not-found i {
  color: #cecece;
  font-size: 60px;
}

.not-found h5 {
  font-size: 18px;
  color: #707070;
}

/* Form */

form label {
  font-size: 13px;
}

form .input-simulate {
  border: 1px solid;
  border-radius: 5px;
  padding: 0px;
}

form .input-simulate small {
  display: none;
  position: absolute;
  top: 125px;
}

form .input-material {
  border: none;
  padding: 0px;
}

form .input-material:focus {
  outline: none;
}

form .input,
form .input-simulate {
  padding: 10px 20px;
  border-color: rgb(204, 204, 204);
  font-size: 13px;
  border-radius: 10px;
  color: #717171;
}

form input[type="text"] {
  border-radius: 10px;
  font-size: 13px;
  padding: 10px 20px;
}

form input[type="text"]::placeholder {
  font-size: 12px;
  color: #717171;
}

form select {
  font-size: 13px !important;
  padding: 10px 20px !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 10px !important;
}

form select:disabled {
  color: #717171;
}

.input[placeholder] {
  text-overflow: ellipsis;
}

form input[type="date"] {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  /* width:465px;*/
  display: inline-block;
}

form input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  font-size: 18px;
  transform: translateX(-5px);
}

form input::-webkit-datetime-edit {
  position: relative;
  left: 20px;
  color: #717171;
  font-size: 12px;
}

form .border-red {
  border: 1px solid #dc3545;
}

/* Buttons */

.btn {
  padding: 8px 18px !important;
  border: none;
  color: black;
  white-space: nowrap;
  border: 1px solid rgb(204, 204, 204);
}

.btn-green {
  font-size: 16px !important;
  background: #53baab !important;
  border: 1px solid #53baab !important;
  border-radius: 0.5rem !important;
  color: white !important;
}

.btn-green-outline {
  font-size: 16px !important;
  background: white;
  border: 1px solid #53baab;
  border-radius: 0.5rem !important;
  color: #53baab !important;
}

.btn-red {
  /* padding: 10px 20px !important;
    border: 1px solid rgb(204, 204, 204) !important; */
  font-size: 16px !important;
  border-radius: 0.5rem !important;
}

.btn-white {
  font-size: 16px !important;
  border-radius: 0.5rem !important;
}

.btn-green-secondary {
  color: #53baab;
  border: 1px solid #53baab;
  background-color: transparent;
}

.link-green-outline {
  cursor: pointer;
  color: #53baab;
  background-color: transparent;
  border: transparent;
}

.link-green-outline:hover,
.btn-green-secondary:hover,
.btn-green-outline:hover {
  color: #53baab;
}

.link-gray-outline {
  color: #707070;
  background-color: transparent;
  border-color: transparent;
}

/* Animate Styles CSS */

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slow {
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

body {
  margin: 0;
}

/* progressbar */

.material-progress-bar-div {
  margin-right: -3rem;
  margin-top: -3rem;
  margin-left: -2rem;
}

.mat-progress-bar-fill::after {
  background-color: #53baab;
}

.validacion-error {
  color: #f95b5b;
}

.bg-gray {
  background-color: #53baab;
}
